import { createFeature, createReducer, on } from '@ngrx/store';
import { EmployeeActions } from './employee.actions';
import { Employee } from 'src/app/shared/models';

export const employeeFeatureKey = 'employee';

export interface EmployeeState {
  employees: Employee[];
  employee: Employee | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: EmployeeState = {
  employees: [],
  employee: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    EmployeeActions.loadEmployees,
    (state): EmployeeState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EmployeeActions.loadEmployeesSuccess,
    (state, { employees }): EmployeeState => ({
      ...state,
      employees,
      loading: false,
      error: null,
    })
  ),
  on(
    EmployeeActions.loadEmployeesFailure,
    (state, { error }): EmployeeState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EmployeeActions.loadEmployee,
    (state): EmployeeState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EmployeeActions.loadEmployeeSuccess,
    (state, { employee }): EmployeeState => ({
      ...state,
      employees: [...state.employees, employee],
      employee: employee,
      loading: false,
      error: null,
    })
  ),
  on(
    EmployeeActions.loadEmployeeFailure,
    (state, { error }): EmployeeState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EmployeeActions.addEmployee,
    (state): EmployeeState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EmployeeActions.addEmployeeSuccess,
    (state, { employee }): EmployeeState => ({
      ...state,

      employees: [...state.employees, employee],

      loading: false,

      error: null,
    })
  ),
  on(
    EmployeeActions.addEmployeeFailure,
    (state, { error }): EmployeeState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EmployeeActions.removeEmployee,
    (state): EmployeeState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EmployeeActions.removeEmployeeSuccess,
    (state, { employeeId }): EmployeeState => ({
      ...state,
      employees: state.employees.filter(employee => employee.id !== employeeId),
      loading: false,
      error: null,
    })
  ),
  on(
    EmployeeActions.removeEmployeeFailure,
    (state, { error }): EmployeeState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EmployeeActions.updateEmployee,
    (state): EmployeeState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EmployeeActions.updateEmployeeSuccess,
    (state, { employee }): EmployeeState => ({
      ...state,
      employees: state.employees.map(item =>
        // item.id === employee.id ? employee : item
        item.id === employee.id ? { ...item, ...employee } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    EmployeeActions.updateEmployeeFailure,
    (state, { error }): EmployeeState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const employeeFeature = createFeature({
  name: employeeFeatureKey,
  reducer,
});
